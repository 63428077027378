// 首页

<template>
  <div class="home minwidth">
    <Banner :fillData="bannerList" width="1200" height="540"/>

    <div class="system-nav tab-move2">
      <div :class="['li',{'hover':liHover==0}]" @mouseenter="visible(0)" @mouseleave="invisible">
        <div class="ico1">
          <p>智能在线客服系统</p>
          <div class="more"></div>
        </div>
      </div>
      <div class="solid"></div>
			<div :class="['li',{'hover':liHover==1}]" @mouseenter="visible(1)" @mouseleave="invisible">
        <div class="ico2">
          <p>AI客服机器人</p>
          <div class="more"></div>
        </div>
			</div>

		</div>

    <div class="home-box1 clearboth" v-for="(item,index) in liInfo" :key="index" v-show="index==liHover">
      <div class="home-box1-img floatleft">
        <img width='380' height="250" :src="item.image" alt="">
      </div>
      <div class="home-box1-text floatleft">
        <p class="fs20">{{item.title}}</p>
        <p class="fs18 mt20">{{item.title}}</p>
        <ul class="home-box1-text-ul">
          <li class="floatleft" v-for='litem in item.list' :key="litem">{{litem}}</li>
        </ul>
      </div>
    </div>


    <!--
    <div class="home-box3 home-box3-bg">
      <ul class="clearboth home-box2-ul clearboth">
        <li v-for="(item,index) in resList2" :key="index" class="floatleft">
          <img :src="item.image" alt="">

        </li>
      </ul>
    </div>
    -->
  </div>
</template>

<script>
import waitInHope from "../../assets/images/waitInHope.png";
import banner01 from "../../assets/images/banner01.jpg";
import banner02 from "../../assets/images/banner02.jpg";
import yesicon from "../../assets/images/yes_icon.png";
import downicon from "../../assets/images/down_icon.png";
export default {
  data() {
    return {
      bannerList:[
        {
          linkUrl:"",  //a链接地址
          picUrl:banner01,   //img地址
        },
        {
          linkUrl:"",  //a链接地址
          picUrl:banner02,   //img地址
        }
      ],
      liHover:0,
      liInfo:[
        {
          image:waitInHope,
          title:"智能在线客服系统",
          miniTile:"最专业的智能客服机器人",
          list:["自动应答","4*24小时在线","数据对接","问题学习","知识库批量导入","常见问题引导"]
        },
        {
          image:waitInHope,
          title:"最专业的智能客服机器人",
          miniTile:"助理精准营销 商机尽在掌握",
          list:["全渠道沟通，一站式管理","多行业定制经验","进准访客画像","强大的智能应用","开放API，第三方平台无限对接","系统稳定 安全可靠"]
        },
        {
          image:waitInHope,
          title:"最专业的智能客服机器人",
          miniTile:"助理精准营销 商机尽在掌握",
          list:["全渠道沟通，一站式管理","多行业定制经验","进准访客画像","强大的智能应用","开放API，第三方平台无限对接","系统稳定 安全可靠"]
        },
        {
          image:waitInHope,
          title:"最专业的智能客服机器人",
          miniTile:"助理精准营销 商机尽在掌握",
          list:["全渠道沟通，一站式管理","多行业定制经验","进准访客画像","强大的智能应用","开放API，第三方平台无限对接","系统稳定 安全可靠"]
        },
      ],

      numList:[
        {
          num:70,
          image:downicon,
          tips:"客服效率提高"
        },{
          num:90,
          image:yesicon,
          tips:"客户满意度高达"
        },{
          num:60,
          image:downicon,
          tips:"运营成本降低"
        },{
          num:30,
          image:downicon,
          tips:"客户转化率提高"
        }
      ],

      resList:[
        {
          image:waitInHope,
          title:"响应速度快",
          text:"3秒必回！上班、下班、休假旅游，随时随地都可以得到温馨回复，没有不方便的时候"
        },
        {
          image:waitInHope,
          title:"服务更专业",
          text:"可识别任何身份信息，再也不用重复自报家门：客服、售后、技术轮流服务，问题得到一次性解决"
        },
        {
          image:waitInHope,
          title:"更值得信赖",
          text:"智能机器人问题解决率超九成，还会只能引导，客服MM专心耐心，再也不会选择困难"
        }
      ],
      resList2:[
        {
          image:waitInHope,
          title:"稳定性服务",
          text:"电信级服务性能，强大系统支撑 可千万级同事在线处理，高并发数据处理"
        },
        {
          image:waitInHope,
          title:"安全性保障",
          text:"集群、三库分离分布式支持 10年以上集团企业服务经验"
        },
        {
          image:waitInHope,
          title:"服务全生命周期",
          text:"公司初创、小有规模、集团上市、贯穿企业发展全过程，帮助客户成功"
        }
      ]
    };
  },
  components: {
    Banner:()=>import("@/components/Banner.vue"),
  },
  // 请求数据
  async asyncData({ params }) {
    // const list = await newsHome();
    // const degreeList = await degreePage({ current: 1, size: 8 }); //学历产品
    // const skillList = await skillPage({ current: 1, size: 8 }); //技能产品

    // return {
    //   newsData: newsData,
    //   degreeLists: degreeLists,
    //   skillLists: skillLists,
    // };
  },
  methods: {
    visible(e){
      console.log(e);
      this.liHover = e;
    },
    invisible(e){

    }
  },
};
</script>

<style lang="scss" scoped>
.home {
  .system-nav{
    position: relative;
    z-index: 10;
    width: 963px; height: 120px; float: left; background-color: #FFFFFF; border-radius: 10px; box-shadow: 0px 5px 10px rgba(0,0,0,0.1); margin-left: 50%; transform: translate(-50%,-50%);
    .li{
      width: 180px; height: 120px; float: left; text-align: center; margin-left: 30px; margin-right: 30px;
      .ico1{ width: 180px; height: 120px; float: left; background: url(../../assets/images/ico03.png) center 30px no-repeat;}
      .ico2{ width: 180px; height: 120px; float: left; background: url(../../assets/images/ico04.png) center 30px no-repeat;}
      .ico3{ width: 180px; height: 120px; float: left; background: url(../../assets/images/ico05.png) center 30px no-repeat;}
      .ico4{ width: 180px; height: 120px; float: left; background: url(../../assets/images/ico06.png) center 30px no-repeat;}
      p{ width: 100%; float: left; font-size: 16px; color: #666666; margin-top: 80px;}
      .more{ display: none; width: 26px; height: 26px; float: left; background: url(../../assets/images/ico10.png) no-repeat; margin-left: 50%; transform: translate(-50%,0); margin-top: 5px;}
    }
    .solid{ width: 1px; height: 50px; float: left; background-color: #c6ccd5; margin-top: 35px;}
    .hover{
      height: 150px; background: url(../../assets/images/bj01.png) no-repeat; margin-top: -15px;
      .ico1{ width: 180px; height: 150px; float: left; background: url(../../assets/images/ico03s.png) center 30px no-repeat;}
      .ico2{ width: 180px; height: 150px; float: left; background: url(../../assets/images/ico04s.png) center 30px no-repeat;}
      .ico3{ width: 180px; height: 150px; float: left; background: url(../../assets/images/ico05s.png) center 30px no-repeat;}
      .ico4{ width: 180px; height: 150px; float: left; background: url(../../assets/images/ico06s.png) center 30px no-repeat;}
      p{ color: #FFFFFF;}
      .more{ display: block;}
    }
  }
  &-box1{
    margin: 40px auto;
    width: 960px;
    height: 260px;
    overflow: hidden;
    &-img{
      margin-right: 50px;
    }
    &-text{
      font-size: 18px;
      color: #2c2d48;
      &-ul{
        width: 500px;
        li{
          width: 210px;
          padding-left: 10px;
          box-sizing: border-box;
          margin-top: 20px;
          position: relative;
          &:nth-of-type(2n+1){
            margin-right: 60px;
          }
          &::before{
            position: absolute;
            content: "";
            height: 3px;
            width: 3px;
            background-color: #000;
            border-radius: 50%;
            left: 0px;
            top: 8px;
          }
        }
        &.home-box-text-ul-yellow{
          li::before{
            height: 6px;
            width: 6px;
            top: 6px;
            border-radius: 0;
            background-color: rgb(241, 207, 9);
          }
        }
      }
      &-btn{

      }
    }

  }
  &-box2{
    margin: 60px auto;
    width: 960px;
    padding-left: 60px;
    box-sizing: border-box;
    text-align: center;
    li{
      margin-top: 30px;
      width: 170px;
      height: 160px;
      border:2px solid $active-text-color;
      margin-left: 50px;
      &:nth-of-type(1){
        margin-left: 0;
      }
      .home-box2-ul-top{
        position: relative;
        margin: 40px 0 30px;
        img{
          position: absolute;
          top: -5px;
          right: 30px;
        }
        .fs16{
          position: absolute;
          right: 43px;
          bottom: 5px;
        }
      }
      p{
        color: $primary-text-color;
      }
    }
  }
  &-box3{
    margin: 60px auto;
    width: 960px;
    text-align: center;
    background-color: rgb(46, 110, 206);
    &.home-box3-bg{
      background-color: #fff;
      li{
        color:#333;
        img{
          height: 90px;
          width: 120px;
          border:1px solid #ccc;
        }
        .fs18{
          margin: 10px 0 15px;
        }
        .fs14{
          padding: 0 70px;
        }
      }
    }
    li{
      width:320px;
      color: #fff;
      padding: 40px 0;
      box-sizing: border-box;
      img{
        height: 80px;
        width: 80px;
        border:1px solid #ccc;
      }
      .fs18{
        margin: 20px 0 25px;
      }
      .fs14{
        line-height: 24px;
        padding: 0 52px;
        box-sizing: border-box;
      }
    }
  }

}

</style>